<template>
  <b-custom-tabs :tabs="tabs" />
</template>

<script>
import tanimlar from './tanimlar.vue'

export default {
  data() {
    return {
      tabs: [
        {
          title: 'Tanımlar',
          key: 'button1',
          component: tanimlar,
        },
        {
          title: 'Bakım',
          key: 'button2',
        },
        {
          title: 'Vize',
          key: 'button3',
        },
        {
          title: 'Sigortalar',
          key: 'button4',
        },
        {
          title: 'Ceza',
          key: 'button5',
        },
        {
          title: 'Tahsis',
          key: 'button6',
        },
      ],
    }
  },
}
</script>
