<template>
  <custom-collapse :collapses="collapses" />
</template>

<script>
export default {
  data() {
    return {
      collapses: [{ title: 'Araç Plakaları' }, { title: 'Sürücüler' }],
    }
  },
}
</script>

<style></style>
